import './HotDice.css';

import React from 'react';
import StoreButtons from '../components/StoreButtons';

class HotDice extends React.Component {


    render(){
        const freeAppstoreURL = "https://apps.apple.com/us/app/the-sex-game-hot-dice/id1072853488?ls=1";
        const freeGoogleplayURL = "https://play.google.com/store/apps/details?id=br.com.newemperium.sgdice";

        return(
            <section id="hotdice" className="hotdice one-page-section">

                <div className="text-center">
                    <h1 className="title"> The Sex Game - Hot Dice!</h1>
                    <div className="dice-row">
                        <img src="images/iphone-hotDice.png" className="img-hotdice" alt="The Sex Game: Hot Dice game screenshot" />
                        <div className="dice-column">
                        <p>The game contains several erotic dice, including the option to create your own pair of dice.
It's already includes a lot of dice with the most diverse actions to increase your enjoyment and pleasure with your partner!</p>
                    
                    <StoreButtons 
                        title="Free Version" 
                        appstoreLink={freeAppstoreURL} 
                        googleplayLink={freeGoogleplayURL}
                        hideGoogle={true}
                    />
                        </div>
                    </div>
                    
                </div>
  
        </section>
        )
    }
}

export default HotDice;