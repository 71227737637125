import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './assets/css/bootstrap.min.css'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyADL2-wFaVPc9nhekqgZrv2rZs8KZOsB9A',
  authDomain: 'new-emperium.firebaseapp.com',
  projectId: 'new-emperium',
  storageBucket: 'new-emperium.appspot.com',
  messagingSenderId: '607505763698',
  appId: '1:607505763698:web:2182f01ccae6e87496e1eb',
  measurementId: 'G-0GQH3DMVQ2',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
