import React from 'react';
import './Main.css';

import Carousel from "./CarouselFullPage";
import 'react-typist/dist/Typist.css';


import Typist from 'react-typist';

class Main extends React.Component {

    constructor(){
        super();
        this.state = {typistIndex: 0};
    }

    render(){


        
        return(
            <section id="home" className="home-1 one-page-section">
                
            <div className="page-table">
                <div className="table-cell text-center">
                <Typist className="x-sign" key={this.state.typistIndex}  onTypingDone={() => this.setState(state => ({ typistIndex: state.typistIndex + 1 }))}>
                    The best apps...
                    <Typist.Backspace count={7} delay={1000} />
                    adult apps...
                    <Typist.Backspace count={13} delay={5000} />
                    sex with fun time...
                    <Typist.Backspace count={20} delay={5000} />
                    adult experience...
                    <Typist.Delay ms={5000} />
                </Typist> 
            
                </div>
            </div>
        </section>
        )
    }
}

export default Main;