import './StoreButtons.css';

import React from 'react';

const StoreButtons = ({title, appstoreLink, googleplayLink, hideGoogle }) => {
    
    return(
        <div className="storebuttons-container">
            <h2>{title}</h2>
            <div className="image-container">
                <a href={appstoreLink} target="_blank" rel="noopener noreferrer">
                <img src="/images/appstore.png" className="image-icon" alt="iOS Appstore icon" />
                </a>

                {hideGoogle ? null : <a href={googleplayLink} target="_blank" rel="noopener noreferrer">
                    <img src="/images/googleplay.png" className="image-icon" alt="Google Play store icon" /> 
                </a> }
              
            </div>
        </div>
    )
    
}

export default StoreButtons;