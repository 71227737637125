import './HotWays.css';

import React from 'react';
import StoreButtons from '../components/StoreButtons';

class HotWays extends React.Component {


    render(){
        const freeAppstoreURL = "https://apps.apple.com/us/app/the-sex-game-hot-ways/id936451240";
        const freeGoogleplayURL = "https://newemperium.com/apps/TSG_FREE_1.8.0.apk";

        const fullAppstoreURL = "https://apps.apple.com/us/app/the-sex-game-hot-ways/id905775482";
        const fullGoogleplayURL = "https://play.google.com/store/apps/details?id=br.com.newemperium.sg";

        return(
            <section id="hotways" className="hotways one-page-section">

                <h1 className="title"> The Sex Game - Hot Ways!</h1>
                <p>The Sex Game: Hot Ways! is a board game created for the amusement of the couple between four walls.
Increases the intimacy of the couple because helps to reveal the thoughts and intentions of the partner, Hot Ways provides a more pleasurable sexual intercourse due to internal events and excitement created by the game.</p>

                <div className="image__container">
                    <img src="images/iphone-hotWays.png" className="image img-ways" alt="The Sex Game: Hot Ways game screenshot"/>
                </div>
                    
                <StoreButtons 
                    title="Free Version" 
                    appstoreLink={freeAppstoreURL} 
                    googleplayLink={freeGoogleplayURL}
                />
                <StoreButtons 
                    title="Full Version" 
                    appstoreLink={fullAppstoreURL} 
                    googleplayLink={fullGoogleplayURL}
                />

            </section>
        )
    }
}

export default HotWays;